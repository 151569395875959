import React from 'react';

import TemplateLegalPage from '../../blocks/templateLegalPage';

import RectangleLink from "../../components/RectangleLink";

import enMessages from '../../i18n/legal/bpa/en.js'
import ruMessages from '../../i18n/legal/bpa/ru.js'

import enMainMessages from '../../i18n/LegalInformation/en.js'
import ruMainMessages from '../../i18n/LegalInformation/ru.js'

const messages = {
  en: enMessages,
  ru: ruMessages,
};

const messagesMain = {
  en: enMainMessages,
  ru: ruMainMessages,
};

const BpaPage = ({ pathContext: { locale } }) => {
  const section = 'bpa';

  return (
    <TemplateLegalPage
      locale={locale}
      messages={messages[locale]}
      messagesMain={messagesMain[locale]}
      section={section}
    >
      {messages[locale].content.map((item) => {
        if (item.items) {
          return (
            <React.Fragment key={item.title}>
              {item.title ? (
                <h4 style={{ margin: '2em 0 0' }}>{item.title}</h4>
              ) : null}

              {item.items.map(((subitem) => {
                return (
                  <RectangleLink
                    key={subitem.link}
                    label={subitem.label}
                    link={subitem.link}
                    title={subitem.title}
                    target="_blank"
                  />
                );
              }))}
            </React.Fragment>
          );
        }

        return (
          <RectangleLink
            key={item.title}
            label={item.label}
            link={item.link}
            target="_blank"
            title={item.title}
          />
        );
      })}
    </TemplateLegalPage>
  );
};

export default BpaPage;
